@import "../../assets/scss/mixins";

.root {
  width: 100%;
  padding: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include min-media(min_md) {
    display: none;
  }

  h1 {
    margin: 0;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    font-feature-settings: 'ss03' on, 'ss02' on;
  }
}
