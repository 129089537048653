@import '../../assets/scss/mixins';

.root {
  position: relative;
  cursor: url("../../assets/img/blackCircle.svg") 8 8, auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .closeBtn {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 40px;
    height: 40px;
  }


  .images {
    overflow-x: auto;
    overflow-y: hidden;

    .image {
      width: 33.33%;

      @include max-media(md) {
        width: auto;
        height: 282px;
      }
    }

    .flexRow {
      display: flex;
    }
  }

  .details {
    flex-grow: 2;
    cursor: url("../../assets/img/whiteCircle.svg") 8 8, auto;
    background-color: black;
    padding: 66px 0 94px;
    color: white;
    display: flex;
    align-items: center;
    position: relative;

    @include max-media(md) {
      padding: 56px 32px 80px;
    }

    .desktopPrev, .desktopNext {
      position: absolute;
      height: 100%;
      width: 50%;

      @include max-media(lg) {
        display: none;
      }
    }

    .desktopPrev {
      cursor: url("../../assets/img/prev.svg") 29 29, auto;
      top: 0;
      left: 0;
      z-index: 2;
    }
    .desktopNext {
      cursor: url("../../assets/img/next.svg") 29 29, auto;
      top: 0;
      right: 0;
      z-index: 2;
    }

    .scrollbar {
      display: none;
      position: absolute;
      top: 24px;

      @include max-media(md) {
        display: block;
        width: calc(100% - 64px);
        height: 1px;
        background-color: rgba(white, 0.4);
      }

      .block {
        height: 1px;
        position: absolute;
        top: 0;
        background-color: white;
      }
    }

    .wrapper {
      width: 100%;
      max-width: 1080px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0;

      @include max-media(lg) {
        padding: 0 80px;
      }
      @include max-media(md) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0;
      }


      .leftColumn {
        max-width: 528px;
        @include max-media(lg) {
          padding-right: 32px;
        }
        @include max-media(md) {
          padding: 0;
        }



        .projectName {
          font-weight: 700;
          font-size: 68px;
          line-height: 64px;
          letter-spacing: 0.06em;
          text-transform: uppercase;
          font-feature-settings: 'ss02' on;
          color: transparent;
          -webkit-text-stroke: 1px white;

          @include max-media(md) {
            font-size: 40px;
            line-height: 48px;
            letter-spacing: 0.02em;
          }
        }
        .subtitle {
          font-weight: 700;
          font-size: 68px;
          line-height: 64px;
          letter-spacing: 0.06em;
          text-transform: uppercase;
          font-feature-settings: 'ss02' on;
          color: white;

          @include max-media(md) {
            font-size: 40px;
            line-height: 48px;
            letter-spacing: 0.02em;
          }
        }

        .description {
          font-weight: 400;
          font-size: 23px;
          line-height: 32px;
          padding: 41px 0 57px;

          p {
            margin-bottom: 32px;

            &:last-of-type {
              margin-bottom: 0;
            }
          }

          .separator {
            margin: 56px 0;
            width: 103px;
            height: 1px;
            background-color: white;
          }

          @include max-media(md) {
            padding: 32px 0 40px;
          }
        }

        a, a:visited {
          cursor: url("../../assets/img/WhiteBgExternalCursor.svg") 29 29, auto;
          color: white;
          text-decoration: none;
        }

        .link {
          position: relative;
          z-index: 10;
          padding: 8px 28px 12px;
          border: 1px solid white;
          border-radius: 32px;
          font-weight: 300;
          font-size: 23px;
          line-height: 29px;
          margin: 8px 16px 8px 0;

          &:hover {
            text-decoration: underline;
          }

          @include max-media(lg) {
            display: none;
          }
        }
      }
      .rightColumn {
        width: 344px;

        @include max-media(md) {
          width: auto;
        }

        .group {
          padding-bottom: 11px;
          margin-bottom: 20px;

          &:last-of-type {
            margin: 0;
          }
        }

        .label {
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          text-transform: uppercase;
          opacity: 0.4;
          padding-bottom: 7px;
        }

        .item {
          font-weight: 300;
          font-size: 23px;
          line-height: 29px;
        }
      }
      .mobileLink {
        display: none;
        align-items: center;
        color: white;
        font-size: 23px;
        line-height: 29px;
        font-weight: 1;
        margin: 16px 0 0;

        @include max-media(lg) {
          display: flex;
        }

        .icon {
          margin-left: 12px;
          display: flex;
        }
      }
    }
  }
}

.desktopUrls {
  display: flex;
  flex-wrap: wrap;
}


.images::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.images {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
