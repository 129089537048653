@import './assets/scss/mixins';

body {
  margin: 0;
  font-family: 'Space Grotesk', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  min-height: 100%;
}

button:focus {
  outline: none;
}

* {
  box-sizing: border-box;
}

p {
  margin: 0;
}

.members-carousel-wrapper {
  display: none;
  @include max-media(md) {
    display: block;
  }

  .carousel-root {
    width: calc(100% + 64px);
    margin: 48px -32px 0;

    .slide {

      &:first-of-type {
        &.selected {
          margin-left: 10%;
        }
      }

      &:last-of-type {
        &.selected {
          margin-right: 10%;
        }
      }
    }

    .memberWrapper {
      padding: 0 20px;

      img {
        border-radius: 50%;
        border: 1px solid black !important;
      }
    }
  }
}

.news-carousel-wrapper {
  display: none;
  @include max-media(md) {
    display: block;
  }
  .carousel-root {
    width: calc(100% + 64px);
    margin: 24px -32px 0;
  }

  .articleWrapper {
    text-decoration: none;
    color: black;

    .articleDate {
      text-align: left;
      font-size: 23px;
      line-height: 32px;
      color: black;
      opacity: 0.2;
    }

    .articleTitle {
      text-align: left;
      margin: 16px 0;
      font-weight: 700;
      font-size: 40px;
      line-height: 48px;
    }

    .articleArrow {
      display: flex;
      width: max-content;
      align-items: flex-start;
      margin-left: -9px;
    }
  }

  .slide {
    padding: 0 32px;
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.memberFirstName, .memberLastName {
  text-align: center;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-feature-settings: 'ss02' on;
  -webkit-text-stroke: 1px black;
  color: transparent;
}

.memberFirstName {
  margin: 16px 0 0;
}

.memberLastName {
  margin: 0 0 8px;
}

.memberRole {
  padding: 4px 16px 8px;
  color: white;
  border-radius: 32px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  background-color: black;
  display: flex;
  justify-content: center;
  width: max-content;
  margin: 0 auto;
  max-width: 300px;
}

.memberProgressBarWrapper {
  margin-top: 56px;
  width: 100%;
  height: 1px;
  background-color: rgba(black, 0.2);
  position: relative;

  .memberProgressBarRect {
    transition: all ease-in-out 0.2s;
    position: absolute;
    top: 0;
    background-color: black;
    height: 1px;
  }
}

.newsProgressBarWrapper {
  margin-top: 24px;
  width: 100%;
  height: 1px;
  background-color: rgba(black, 0.2);
  position: relative;

  .newsProgressBarRect {
    transition: all ease-in-out 0.2s;
    position: absolute;
    top: 0;
    background-color: black;
    height: 1px;
  }
}

.productOverlay {
  display: none;
  position: absolute;
  top: -105px;
  left: calc(50% - 172px);
  width: 344px;
  height: 258px;
  z-index: 0;
  border: 1px solid black;

  .productThumbnail {
    width: 100%;
    height: 100%;
  }

  .productOverlayPlus {
    position: absolute;
    top: 20px;
    right: 16px;

    a {
      display: flex;
    }
  }
  @include min-media(min_md) {
    height: 396px;
    width: 528px;
    right: -40px;
    top: -174px;
    left: auto;
  }

  @include min-media(min_lg) {
    display: none !important;
  }
}

.activeProduct {
  opacity: 1 !important;

  p {
    z-index: 3;
    position: inherit;
  }

  .productOverlay {
    display: block;
  }
}

.tabletMemberAbsolute {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  top: -145px;
  right: 0;
  //transition: all linear 0.2s;

  .avatar {
    width: 344px;
    height: 344px;
    border-radius: 50%;
    border: 1px solid black;
  }

  .role {
    display: flex;
    padding: 4px 16px 8px;
    background-color: black;
    color: white;
    border-radius: 32px;
    font-size: 16px;
    line-height: 20px;
    margin-top: 102px;
    text-align: center;
  }

}

.activeMember {
  opacity: 1 !important;

  .tabletMemberAbsolute {
    opacity: 1;
  }
}

.drawer-container {
  --transition-speed: 0.3s;
}

.drawer {
  background: black;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: fixed;
  //box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  transition: transform var(--transition-speed) ease;
  z-index: 1000;
}

.drawer.left {
  top: 0;
  left: 0;
  transform: translateX(-105%);
}

.drawer.right {
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.drawer.top {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  transform: translateY(-100%);
  height: 40%;
}

.drawer.bottom {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  transform: translateY(100%);
  height: 40%;
}

.drawer-container.in.open .left,
.drawer-container.in.open .right {
  transform: translateX(0);
}

.drawer-container.in.open .top,
.drawer-container.in.open .bottom {
  transform: translateY(0);
}

.backdrop {
  visibility: hidden;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity var(--transition-speed) ease,
  visibility var(--transition-speed) ease;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
  z-index: 0;
}

.drawer-container.in.open .backdrop {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  z-index: 999;
}

.mobileMenu {
  background-color: black;
  position: relative;
  height: 100%;
  padding: 0 32px 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .mobileMenuHeader {
    padding: 32px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-weight: 700;
      font-size: 28px;
      line-height: 32px;
      font-feature-settings: 'ss03' on, 'ss02' on;
      color: white;
    }
  }

  .mobileMenuList {
    a {
      display: flex;
      text-decoration: none;
      width: max-content;

      &:focus, &:active {
        outline: none;
      }
    }

    .mobileMenuItem {
      color: white;
      margin-bottom: 24px;
      font-weight: 500;
      font-size: 28px;
      line-height: 36px;
      font-feature-settings: 'ss02' on;

      &.selected {
        text-decoration: underline;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .mobileLanguageSelector {
    margin-top: 32px;
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    font-feature-settings: 'ss02' on;
    color: white;
    opacity: 0.4;
  }

  .closeMobileMenu {
    padding: 0;
    width: 48px;
    height: 48px;
    position: absolute;
    right: 32px;
    bottom: 44px;
    background: none;
    border: none;

    svg {
      width: 48px;
      height: 48px;
    }
  }
}

.glitch {
  display: block;
  @include max-media(md) {
    height: calc(100vh - 224px);
  }
}
.breakWord {
  word-break: break-word;
  height: auto;
}
