@import '../../assets/scss/mixins';

.text {
  position: relative;
  z-index: 1;
  font-weight: 700;
  font-size: 68px;
  line-height: 80px;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  font-feature-settings: 'ss02' on;
  color: transparent;
  -webkit-text-stroke: 1px black;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  //transition: all linear 0.2s;

  padding: 0 160px;

  .item {
    position: relative;
    margin: 0 36px;
    //transition: opacity ease-in-out 200ms;

    .absoluteWrapper {
      //transition: opacity ease-in-out 200ms;
      opacity: 0;
      position: absolute;
      left: 0;
      top: 75px;
      width: 100%;
      display: flex;
      justify-content: center;

      .role {
        display: flex;
        padding: 4px 16px 8px;
        background-color: black;
        color: white;
        border-radius: 32px;
        font-size: 16px;
        line-height: 20px;
      }
    }

    &.gray {
      opacity: 0.1;
    }

    &:hover {
      opacity: 1;

      .absoluteWrapper {
        opacity: 1;
      }
    }
  }

  @include max-media(lg) {
    display: none;
  }
}
