.container {
  margin-top: 60px;
  border-top: 1px solid black;
  width: 280px;
  display: flex;
  //transition: all ease-in-out 200ms;

  a {
    color: black;
    text-decoration: none;
    cursor: url("../../assets/img/BlackBgExternalCursor.svg") 28 28, auto;
  }

  .date {
    padding: 18px 0;
    font-size: 23px;
    line-height: 32px;
  }

  .text {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
  }

  &.gray {
    opacity: 0.1;
  }

  &:hover {
    opacity: 1;
    .text {
      text-decoration: underline;
    }
  }
}
