@import '../../assets/scss/mixins';

.root {
  display: none;
  padding: 0 80px;

  @include max-media(lg) {
    display: block;
  }

  @include max-media(md) {
    display: none;
  }

  .member {
    opacity: 0.1;
    //transition: all linear 0.2s;
    position: relative;

    .name {
      z-index: 1;
      position: relative;
      font-weight: 700;
      font-size: 48px;
      line-height: 64px;
      text-align: left;
      letter-spacing: 0.06em;
      text-transform: uppercase;
      font-feature-settings: 'ss02' on;
      color: transparent;
      -webkit-text-stroke: 1px black;
    }
  }
}
