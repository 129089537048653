@import '../../assets/scss/mixins';

.root {
  padding: 40px 0 137px;
  background-color: white;

  .wrapper {
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;

    .flexRow {
      display: flex;
      align-items: center;

      .line {
        height: 1px;
        background-color: black;
        flex-grow: 2;
      }

      .logo {
        padding: 0 24px;
        svg {
          width: 64px;
          height: 64px;
        }
      }
    }

    .textRow {
      margin-top: 63px;
      display: flex;
      width: 100%;

      .item {
        padding: 0 12px;
        width: 50%;
        font-feature-settings: 'ss02' on, 'zero' on;

        &:last-of-type {
          margin-right: 0;
        }

        .text {
          font-size: 23px;
          line-height: 32px;
          text-align: center;
        }

        .padded {
          padding-bottom: 32px;
        }

        .underline {
          text-decoration: underline;
        }

        .linkWrapper {
          display: flex;
          justify-content: center;
        }

        a {
          color: black;
        }
      }
    }

    .mobileLogo {
      display: none;
    }
  }

  @include max-media(md) {
    padding: 0;
    .wrapper {
      width: calc(100% - 48px);
      border-top: 4px solid black;
      padding: 16px 0 44px;
      margin: 0 24px;

      .flexRow {
        display: none;
      }

      .textRow {
        display: block;
        margin: 0;

        .item {
          width: 100%;
          margin: 0;
          padding: 32px 0 0;

          .text {
            text-align: left;
          }

          .padded {
            padding: 0;
          }

          .linkWrapper {
            justify-content: flex-start;
          }
        }
      }

      .mobileLogo {
        margin-top: 54px;
        display: flex;
        justify-content: flex-start;
        width: max-content;
      }
    }
  }
}
