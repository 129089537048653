@import "../../assets/scss/mixins";

.root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include max-media(md) {
    display: none;
  }

  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 910px;
    width: 100%;

    button {
      pointer-events: auto;
      cursor: url("../../assets/img/blackCircle.svg") 8 8, auto;
      background: white;
      border: none;
      margin: 0;
      position: relative;
      display: inline-block;

      @include max-media(lg) {
        display: none;
      }
    }

    button::before,
    button::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .item {
      padding: 7px 16px 9px;
      font-size: 23px;
      line-height: 32px;
      font-feature-settings: 'ss03' on;
    }


    .item {
      overflow: hidden;
      color: #fff;
    }

    .item span {
      display: block;
      position: relative;
      mix-blend-mode: difference;
      z-index: 10;
    }

    .item:hover span {
      animation: MoveScaleUpInitial 0.3s forwards, MoveScaleUpEnd 0.3s forwards 0.3s;
    }

    @keyframes MoveScaleUpInitial {
      to {
        transform: translate3d(0,-105%,0) scale3d(1,2,1);
        opacity: 0;
      }
    }

    @keyframes MoveScaleUpEnd {
      from {
        transform: translate3d(0,100%,0) scale3d(1,2,1);
        opacity: 0;
      }
      to {
        transform: translate3d(0,0,0);
        opacity: 1;
      }
    }

    .item::before {
      content: '';
      background: #000;
      width: 120%;
      height: 0;
      padding-bottom: 120%;
      top: -110%;
      left: -10%;
      transform: translate3d(0,68%,0) scale3d(0,0,0);
    }

    .item:hover::before {
      transform: translate3d(0,0,0) scale3d(1,1,1);
      transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
    }

    .item::after {
      content: '';
      background: #000;
      transform: translate3d(0,-100%,0);
      transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
    }

    .item:hover::after {
      transform: translate3d(0,0,0);
      transition-duration: 0.05s;
      transition-delay: 0.4s;
      transition-timing-function: linear;
    }


    .title {
      font-weight: bold;
      font-size: 40px;
      line-height: 48px;
      font-feature-settings: 'ss03' on, 'ss02' on;
    }
  }
  .languageSelector {
    padding: 7px 16px 9px;
    font-size: 23px;
    line-height: 32px;
    font-feature-settings: 'ss03' on;
    color: rgba(black, 0.4);

    @include max-media(lg) {
      display: none;
    }
  }

  .menuBtn {
    display: none;

    @include max-media(lg) {
      display: flex;
    }

    @include max-media(md) {
      display: none;
    }
  }
}
