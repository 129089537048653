@import '../../assets/scss/mixins';

.wrapper {
  box-sizing: border-box;
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 46px;

    .listItem {
      padding: 8px 28px 12px;
      font-size: 23px;
      line-height: 28px;
      font-weight: 300;
      margin: 8px;
      border: 1px dashed #FFFFFF;
      border-spacing: 2px;
      border-radius: 32px;
    }
  }

  &.mobileHidden {
    @include max-media(md) {
      display: none;
    }
  }

  @include max-media(md) {
    position: relative;
    width: calc(100% + 64px);
    overflow-x: scroll;
    overflow-y: hidden;
    margin: 0 -32px;
    display: flex;
    //flex-direction: column;

    .list {
      min-width: 946px;
      flex-basis: 946px;
    }
  }
}
