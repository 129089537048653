$init: px;

$xl: 1599.98;
$lg: 1024;
$md: 767;



$min_xl: 1600;
$min_lg: 1024.05;
$min_md: 767.05;


/* MEDIA */

@mixin max-media($media) {
  @if $media == xl {
    @media screen and (max-width: $xl + $init) {
      @content;
    }
  } @else if $media == lg {
    @media screen and (max-width: $lg + $init) {
      @content;
    }
  } @else if $media == md {
    @media screen and (max-width: $md + $init) {
      @content;
    }
  }
}

@mixin min-media($media) {
  @if $media == min_xl {
    @media only screen and (min-width: $min_xl + $init) {
      @content;
    }
  } @else if $media == min_lg {
    @media only screen and (min-width: $min_lg + $init) {
      @content;
    }
  } @else if $media == min_md {
    @media only screen and (min-width: $min_md + $init) {
      @content;
    }
  }
}

/*How it's use*/
@include max-media(xl) {
}
@include max-media(lg) {
}
@include max-media(md) {
}


@include min-media(min_xl) {
}
@include min-media(min_lg) {
}
@include min-media(min_md) {
}
