@import '../../assets/scss/mixins';
a {
  cursor: inherit;
}
.root {
  width: 100%;
  position: relative;
  cursor: url("../../assets/img/blackCircle.svg") 8 8, auto;

  .productDetails {
    position: absolute;
    width: 528px;
    height: 396px;
    z-index: 0;
    pointer-events: none;
    //transition: opacity ease-in-out 200ms;
    border: 1px solid black;

    img {
      width: 100%;
      height: 100%;
    }

    @include max-media(lg) {
      display: none;
    }
  }

  .productDetailsListMode {
    position: fixed;
    width: 528px;
    height: 396px;
    top: calc(50% - 198px);
    right: 180px;
    //transition: opacity ease-in-out 200ms;
    border: 1px solid black;

    img {
      width: 100%;
      height: 100%;
    }

    @include max-media(lg) {
      display: none;
    }
  }

  .memberPhoto {
    position: absolute;
    width: 344px;
    height: 344px;
    //transition: opacity ease-in-out 200ms;
    z-index: 1;

    @include max-media(lg) {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 1px solid black;
    }
  }

  .mobileOnlySectionHeader {
    display: none;
    @include max-media(md) {
      display: block;
      font-size: 23px;
      line-height: 32px;
      font-feature-settings: 'ss02' on;
      padding: 16px 0 32px;
    }
  }
  .tabletOnlySectionHeader {
    display: none;
    @include max-media(lg) {
      display: block;
      font-size: 23px;
      line-height: 32px;
      font-feature-settings: 'ss02' on;
      padding: 16px 0 32px;
    }
  }

  .mobileHidden {
    @include max-media(md) {
      display: none;
    }
  }

  .homeSection {
    min-height: 100vh;
    display: flex;
    align-items: center;

    .text {
      max-width: 1240px;
      padding: 0 80px;
      width: 100%;
      margin: 0 auto;
      font-size: 40px;
      line-height: 48px;
      text-align: center;
      font-feature-settings: 'ss02' on;
      word-break: break-all;
    }

    @include max-media(md) {
      display: block;
      padding: 32px 32px 0;
      min-height: auto;

      .text {
        font-weight: 500;
        font-size: 28px;
        line-height: 36px;
        font-feature-settings: 'ss02' on;
        text-align: left;
        padding: 0;
      }
    }
  }

  .workSection {
    min-height: 100vh;
    margin-bottom: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.left {

      @include min-media(min_lg) {
        margin-top: -35%;
        align-items: flex-start;
        padding: 35% 180px 180px;

        .wrapper {
          width: 100%;
        }
      }
    }

    .wrapper {
      .text {
        position: relative;
        z-index: 1;
        //width: 140%;
        //margin: 0 -20%;
        font-weight: 700;
        font-size: 68px;
        line-height: 80px;
        text-align: center;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        font-feature-settings: 'ss02' on;
        color: transparent;
        -webkit-text-stroke: 1px black;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        //transition: all linear 0.2s;

        padding: 0 160px;

        //&.scrollLeft {
        //  margin-left: 0;
        //}
        //
        //&.scrollRight {
        //  margin-left: -40%;
        //}

        span {
          color: black;
        }

        .item {
          cursor: url("../../assets/img/plusCursorBlackBg.svg") 20 20, auto;
          padding: 0 36px;
          //transition: all ease-in-out 200ms;
          position: relative;

          &.gray {
            opacity: 0.1;
          }

          &:hover {
            opacity: 1;

            @include max-media(lg) {
              opacity: 0.1;
            }
          }

          a {
            color: transparent;
            text-decoration: none;
          }
        }

        &.listMode {
          display: block;
          text-align: left;
          padding: 0;
          width: 100%;

          .item {
            padding: 0;

            a {
              display: block;
              width: 100%;
            }
          }
        }
      }
    }

    .viewModeToggle {
      margin-top: 57px;
      font-size: 23px;
      line-height: 32px;
      text-align: center;
      text-decoration-line: underline;
      font-feature-settings: 'ss02' on;
      color: #000000;

      @include max-media(lg) {
        display: none;
      }
    }

    @include max-media(lg) {
      display: block;
      padding: calc(50vh - 80px) 80px 32px;
      .wrapper {
        .text {
          display: block;
          text-align: left;
          font-size: 64px;
          line-height: 68px;
          letter-spacing: 0.02em;
          padding: 0;

          .item {
            padding: 0;
            opacity: 0.1;
          }
        }
      }
    }
    @include max-media(md) {
      padding: calc(50vh - 80px) 32px 32px;

      .wrapper {
        .text {
          font-size: 40px;
          line-height: 48px;
        }
      }
    }
  }

  .flowSection {
    cursor: url("../../assets/img/whiteCircle.svg") 8 8, auto;
    min-height: 100vh;
    background-color: black;
    color: white;
    text-align: center;
    padding: 135px 0 171px;

    .wrapper {
      width: 100%;
      max-width: 1240px;
      margin: 0 auto;
      padding: 0 80px;

      @include max-media(md) {
        padding: 0;
      }

    }

    .title, .use {
      font-size: 23px;
      line-height: 32px;
      font-feature-settings: 'ss02' on;
      margin-bottom: 34px;
    }

    .description {
      font-weight: bold;
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 38px;
    }

    .use {
      margin-bottom: 26px;
    }

    .deliverBoldText {
      font-weight: 700;
      font-size: 23px;
      line-height: 32px;
      margin-bottom: 16px;
    }

    .deliverText {
      font-size: 23px;
      line-height: 32px;
    }

    .expandRow, .collapseRow {
      display: none;
    }

    @include max-media(md) {
      text-align: left;
      padding: 48px 32px;

      .title {
        margin-bottom: 32px;
      }

      .description {
        font-size: 28px;
        line-height: 36px;
      }

      .expandRow,.collapseRow {
        display: flex;
        width: max-content;

        .text {
          color: white;
          padding: 3px 0 9px 16px;
          font-size: 23px;
          line-height: 29px;
          text-decoration-line: underline;
        }
      }

      .collapseRow {
        margin-top: 32px;
      }
    }
  }

  .teamSection {
    padding: 112px 0;

    .title {
      text-align: center;
      font-size: 23px;
      line-height: 32px;
      font-feature-settings: 'ss02' on;
    }

    .description {
      padding: 34px 80px 69px;
      width: 100%;
      max-width: 1240px;
      margin: 0 auto;
      font-size: 40px;
      line-height: 48px;
      font-weight: 300;
      text-align: center;
      font-feature-settings: 'ss02' on, 'ss03' on;
    }

    @include max-media(md) {
      padding: 48px 32px;

      .description {
        padding: 32px 0 0;
        font-size: 28px;
        line-height: 36px;
      }
    }
  }

  .newsSection {
    padding: 119px 167px;

    @include max-media(lg) {
      padding: 119px 80px;
    }

    .wrapper {
      width: 100%;
      max-width: 1080px;
      margin: 0 auto;

      .title {
        font-size: 23px;
        line-height: 32px;
        text-align: center;
        font-feature-settings: 'ss02'
      }

      .articles {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
      }
    }
    @include max-media(md) {
      padding: 48px 32px;
      .wrapper {
        .articles {
          display: none;
        }
      }
    }
  }

  .menuBtn {
    display: none;
    position: fixed;
    right: 32px;
    bottom: 44px;
    z-index: 10;
    @include max-media(md) {
      display: flex;
    }
  }
}
